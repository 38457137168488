import Vue from "vue";
import VueRouter from "vue-router";
import Messages from "@/views/Messages.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import store from "@/store";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "home",
    component: Messages
  },
  { path: "*", component: PageNotFound },
  {
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>  import(/* webpackChunkName: "admin" */ `@/views/Admin.vue`)
  }
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'admin' && !store.getters.isAdmin) next({ name: 'home' })
  else next()
})

export default router;
