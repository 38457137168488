import Vue from "vue";
import Vuex from "vuex";
import axios from "@/plugins/axios";
import auth from "./modules/auth";
import user from "./modules/user";
import users from "./modules/users";
import player from "./modules/player";
import setting from "./modules/setting";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    messages: []
  },
  mutations: {
    updateQuery: (state, query) => {
      state.messages.push({
        uid: state.messages.length,
        isMe: true,
        tts_text: query
      });
    },
    updateRequest: (state, { index, data }) => {
      const items = [...state.messages];
      const payload = {
        uid: index,
        isMe: false,
        isLoading: data ? false : true
      };
      if (data) {
        payload.tts_text = data.tts_text;
        payload.data = data;
      }
      items[index] = payload;
      state.messages = items;
    }
  },
  actions: {
    sendQuery({ commit, state }, { query }) {
      const language = state.setting.language.code;
      const environment = state.setting.environment.code;
      const { vin, authID, version } = state.setting;
      const allowedDomains = state.user.options.allowedDomains;
      //Update on the UI for the query
      commit("updateQuery", query);
      // find the next index
      const index = state.messages.length;
      axios
        .post("/mvap/text", {
          query,
          language,
          environment,
          allowedDomains,
          vin,
          authID,
          version
        })
        .then((response) => {
          const data = response.data;
          commit("updateRequest", { index, data });
        })
        .catch((error) => {
          console.error("mvap", error);
        });
      commit("updateRequest", { index });
    }
  },
  modules: { auth, user, users, player, setting }
});
