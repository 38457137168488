<template>
  <v-container fluid class="fill-height" v-resize="onResize">
    <virtual-list
      :style="{ height: `${height}px` }"
      class="stream scroll-touch mb-3"
      :data-key="'uid'"
      :estimate-size="100"
      :data-sources="messages"
      :data-component="item"
      @resized="onItemRendered"
      ref="vsl"
    />
    <editor :send="sendMessage" ref="editor" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {
    editor: () => import("../components/Editor.vue"),
    "virtual-list": () => import("vue-virtual-scroll-list")
  },
  computed: {
    ...mapState(["messages"])
  },
  data: () => ({
    item: () => import("../components/Bubble.vue"),
    height: window.innerHeight - 160
  }),
  mounted() {
    this.onResize();
  },
  methods: {
    ...mapActions(["sendQuery"]),
    onResize() {
      this.height = window.innerHeight - 160;
    },
    sendMessage(message) {
      this.sendQuery({ query: message });
    },
    onItemRendered() {
      const length = this.messages.length || 0;
      if (this.$refs.vsl) {
        this.$refs.vsl.scrollToIndex(Math.max(0, length - 2));
      }
    }
  }
};
</script>

<style scoped>
.stream {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.stream.overflow {
  flex-direction: column;
}

</style>
