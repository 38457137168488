<template>
  <v-app id="inspire">
    <v-app-bar app flat>
      <router-link :to="{ name: 'home' }" class="brand">
        <img
          src="@/assets/logo.svg"
          alt="Mercedes-Benz"
          contain
          height="30"
          width="30"
        />
        <div class="text-uppercase text-h6 px-2">#HeyMercedes - Chat Bot</div>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn icon class="hidden-lg-and-up" @click.stop="drawer = !drawer">
        <v-icon>mdi-account-details</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer width="320" app right v-model="drawer">
      <settings />
    </v-navigation-drawer>

    <v-main v-resize="onResize">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    settings: () => import("../components/Settings.vue")
  },
  data: () => ({ drawer: false }),
  mounted() {
    this.onResize();
    this.fetchUser();
  },
  methods: {
    onResize() {
      if (this.drawer) return;
      this.drawer = window.innerWidth > 1264.0;
    },
    ...mapActions(["fetchUser"])
  }
};
</script>

<style scoped>
.brand {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: white;
}
</style>