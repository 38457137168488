export default {
  namespaced: true,
  state: {
    languages: [{ code: "eng-USA", name: "English (US)" }],
    language: { code: "eng-USA", name: "English (US)" },
    environments: [
      { code: "nonprod", name: "Non Production" },
      { code: "prod", name: "Production" }
    ],
    environment: { code: "nonprod", name: "Non Production" },
    vin: "",
    authID: "",
    version: "",
    textSpeech: false,
    vins: [],
    authIDs: [],
    versions: []
  },
  getters: {},
  mutations: {
    SAVEENVIRONMENT: (state, data) => {
      state.environment = data;
    },
    SAVELANGUAGE: (state, data) => {
      state.language = data;
    },
    SAVEVIN: (state, data) => {
      const index = state.vins.findIndex($0 => $0 === data);
      state.vin = data;
      if (index > -1) {
          state.vins.splice(index, 1);
      }
      state.vins.splice(0, 0, data);
    },
    SAVEAUTHID: (state, data) => {
      state.authID = data;
      const index = state.vins.findIndex($0 => $0 === data);
      if (index > -1) {
          state.authIDs.splice(index, 1);
      }
      state.authIDs.splice(0, 0, data);
    },
    SAVEVERSION: (state, data) => {
      state.version = data;
      const index = state.versions.findIndex($0 => $0 === data);
      if (index > -1) {
          state.versions.splice(index, 1);
      }
      state.versions.splice(0, 0, data);
    },
    SAVETEXTSPEECH: (state, data) => {
      state.textSpeech = data;
    }
  },
  actions: {
    saveEnvironment({ commit }, data) {
      commit("SAVEENVIRONMENT", data);
    },
    saveLanguage({ commit }, data) {
      commit("SAVELANGUAGE", data);
    },
    saveAuthID({ commit }, data) {
      commit("SAVEAUTHID", data);
    },
    saveVin({ commit }, data) {
      commit("SAVEVIN", data);
    },
    saveVersion({ commit }, data) {
      commit("SAVEVERSION", data);
    },
    saveTextSpeech({ commit }, data) {
      commit("SAVETEXTSPEECH", !!data);
    }
  }
};
