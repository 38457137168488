"use strict";

import Speech from "speak-tts";

const speech = new Speech();

if (!speech.hasBrowserSupport()) {
  // returns a boolean
  console.error("The browser is not supported");
}

speech
  .init({
    volume: 1,
    lang: "en-US",
    rate: 1,
    pitch: 1,
    voice: "Samantha",
    splitSentences: true,
    listeners: {}
  })
  .then((data) => {
    console.log("Speech is ready, voices are available.", data);
  })
  .catch((e) => {
    console.log("An error occured while initializing:", e);
  });

const play = async (text) => {
  return new Promise((resove, reject) => {
    speech
      .speak({
        text,
        listeners: {
          onstart: () => {
            console.log("Start utterance");
          },
          onend: () => {
            console.log("End utterance");
          },
          onresume: () => {
            console.log("Resume utterance");
          },
          onboundary: (event) => {
            console.log(
              event.name +
                " boundary reached after " +
                event.elapsedTime +
                " milliseconds."
            );
          }
        }
      })
      .then((data) => {
        console.log("Success !", data);
        resove(data);
      })
      .catch((e) => {
        console.error("An error occurred :", e);
        reject(e);
      });
  });
};
export default { play };
