<template>
  <div id="app" class="background">
    <v-fade-transition>
      <component :is="layout">
        <router-view />
      </component>
    </v-fade-transition>
  </div>
</template>

<script>
import Login from "@/views/Login.vue";
import Default from "@/views/Dashboard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Login,
    Default
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isChangedRequired", "skipPasswordChange"]),
    layout() {
      if (!this.isLoggedIn) return "Login";
      if (!this.isChangedRequired) return "Default";
      if (this.isChangedRequired && this.skipPasswordChange) {
        return "Default";
      }
      return "Login";
    }
  },
  mounted() {
    document.title = "Try #HeyMercedes";
  }
};
</script>

<style scoped>
.background {
  background-color: black;
}
</style>
