import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    users: []
  },
  getters: {},
  mutations: {
    updateUsers: (state, data) => {
      state.users = data;
    },
    addUser: (state, data) => {
      state.users.push(data);
    },
    deleteUser: (state, data) => {
      const index = state.users.findIndex(
        ($0) => $0.username === data.username
      );
      if (index < 0) return;
      state.users.splice(index, 1);
    },
    updateUser: (state, data) => {
      const index = state.users.findIndex(
        ($0) => $0.username === data.username
      );
      if (index < 0) return;
      state.users.splice(index, 1, data);
    }
  },
  actions: {
    fetchUsers({ commit }) {
      return axios
        .get("/users")
        .then((response) => {
          const data = response.data;
          commit("updateUsers", data);
        })
        .catch((error) => {
          console.error("fetchUsers", error);
        });
    },
    addUser({ commit }, payload) {
      return axios
        .post("/users", payload)
        .then((response) => {
          const data = response.data;
          commit("addUser", data);
        })
        .catch((error) => {
          console.error("addUser", error);
        });
    },
    deleteUser({ commit }, identifier) {
      return axios
        .delete(`/users/${identifier}`)
        .then((response) => {
          const data = response.data;
          commit("deleteUser", data);
        })
        .catch((error) => {
          console.error("deleteUser", error);
        });
    },
    updateUser({ commit }, payload) {
      return axios
        .put(`/users/${payload.username}`, payload)
        .then((response) => {
          const data = response.data;
          commit("updateUser", data);
        })
        .catch((error) => {
          console.error("updateUser", error);
        });
    }
  }
};
