import axios from "@/plugins/axios";

const state = {
  options: {},
  username: "",
  role: "member",
  firstName: "",
  lastName: ""
};

const getters = {
  isAdmin: state => state.role === "admin",
  email: state => state.username,
  name: state => {
    const array = [];
    if (state.firstName) {
      array.push(state.firstName);
    }
    if (state.lastName) {
      array.push(state.lastName);
    }
    return array.join(' ');
  },
  avatar: state => {
    const array = [];
    if (state.firstName) {
      array.push(state.firstName[0]);
    }
    if (state.lastName) {
      array.push(state.lastName[0]);
    }
    return array.join('').toUpperCase();
  }
};

const actions = {
  fetchUser({ commit }) {
    return axios
      .get("/user")
      .then(response => {
        const data = response.data;
        commit("saveUser", data);
      })
      .catch(error => {
        console.error("fetchUser", error);
      });
  }
};

const mutations = {
  saveUser: (state, data) => {
    state.username = data.username;
    state.firstName = data.firstName;
    state.lastName = data.lastName;
    state.role = data.role || 'member';
    state.options = data.options || {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
