<template>
  <v-app>
    <v-main>
      <div class="background">
        <video autoplay muted poster="@/assets/poster.png" loop>
          <source src="@/assets/mbux.mp4" type="video/mp4" />
        </video>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="8" md="4" lg="4">
              <v-card elevation="2" class="pa-8">
                <div class="text-center text-h6" v-if="!isLoggedIn">
                  #HeyMercedes
                </div>
                <div class="mt-6" v-if="!isLoggedIn">
                  <v-form>
                    <v-text-field
                      label="Enter your email"
                      name="email"
                      v-model="username"
                      prepend-inner-icon="mdi-email"
                      type="email"
                      class="rounded-10 pb-4"
                      outlined
                      @keyup.enter="checkStatus"
                      hide-details
                      :error="!!errorMessage"
                    />
                    <v-text-field
                      label="Enter your password"
                      name="password"
                      v-model="password"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                      class="rounded-10 pb-4"
                      outlined
                      @keyup.enter="checkStatus"
                      hide-details
                      :error="!!errorMessage"
                    />
                    <div
                      v-if="errorMessage"
                      class="text-left error--text pb-4 px-4"
                    >
                      {{ errorMessage }}
                    </div>

                    <v-btn
                      class="rounded-10"
                      color="primary"
                      x-large
                      block
                      :loading="isLoading"
                      :disabled="isLoginDisabled"
                      @click="submit"
                    >
                      Log In
                    </v-btn>
                  </v-form>
                </div>
                <change-password v-else />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    "change-password": () => import("../components/ChangePassword.vue")
  },

  data: () => ({
    username: "",
    password: "",
    errorMessage: "",
    showPassword: false,
    isLoading: false
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
    isLoginDisabled() {
      return !(!!this.username.trim() && !!this.password.trim());
    }
  },
  methods: {
    checkStatus() {
      if (this.isLoginDisabled) return;
      this.submit();
    },
    submit() {
      this.errorMessage = "";
      this.isLoading = true;
      this.login({ username: this.username, password: this.password })
        .catch((error) => {
          this.errorMessage = error.statusText;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    ...mapActions(["login"])
  }
};
</script>

<style scoped>
.background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.background video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
