import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: colors.blue,
        secondary: colors.grey.darken3,
        accent: colors.yellow.accent4,
        error: colors.red.accent2,
        info: colors.blue,
        success: colors.green,
        warning: colors.orange.darken1
      }
    },
  },
});
