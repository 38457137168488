import speech from "@/services/speech";
import player from "@/services/howler";

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
    addAudio({ dispatch }, { pre, tts, post, urls }) {
      const inputs = [];
      if (pre) {
        inputs.push({ data: pre, type: 'text' });
      }
      if (tts) {
        inputs.push({ data: tts, type: 'text' });
      }
      if (urls) {
        inputs.push({ data: urls, type: 'url' });
      }
      if (post) {
        inputs.push({ data: post, type: 'text' });
      }
      if (inputs.length === 0) return;
      dispatch('play', inputs);
    },
    async play(stores, inputs) {
      for (const input of inputs) {
          if (input.type === 'text') {
            const text = await speech.play(input.data)
            console.log("text play completed", text);
          }
          else {
            const urls = await player.play(input.data)
            console.log("url play completed", urls);
          }
      }
    }
  }
};
