import { Howl, Howler } from "howler";

Howler.mobileAutoEnable = true;

const play = async (urls) => {
  return new Promise((resolve, reject) => {
    const player = new Howl({
      src: urls,
      format: ["mp3", "aac"],
      html5: true
    });
    player.on("end", (id) => {
      console.log("Finished!", id);
      resolve(urls);
    });
    player.on("playerror", (id, error) => {
      console.log("playerror with ", id, error);
      reject(error);
    });
    player.on("loaderror", (id, error) => {
      console.log("loaderror with ", id, error);
      reject(error);
    });
    player.on("load", () => {
      console.log("Loading...!");
    });
    player.play();
  });
};

export default { play };
