import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "http://localhost:3200/api" : "/api"
});

instance.interceptors.request.use((config) => {
  const token = store.state.auth.token;
  if (token) {
    config.headers.authorization = `${token}`;
  }
  return config;
});

instance.interceptors.response.use(undefined, error => {
  let { status, statusText, data} = error.response
  if (data.message) {
    statusText = data.message;
  }
  return Promise.reject({status, statusText, data});
})

export default instance;
