import axios from "@/plugins/axios";

const state = {
  token: window.sessionStorage.getItem("k-chat-bot-session"),
  isChangedRequired: window.sessionStorage.getItem("k-chat-bot-session.changed")  === 'true',
  skipPasswordChange: false
};

const getters = {
  isLoggedIn: state => !!state.token,
  isChangedRequired: state => !!state.isChangedRequired,
  skipPasswordChange: state => state.skipPasswordChange
};

const actions = {
  logout: ({ commit }) => {
    commit("saveLogin", {});
  },
  skipPasswordChange: ({ commit }) => {
    commit("skipPasswordChange");
  },
  login({ commit }, { username, password }) {
    return axios
      .post("/auth/login", { username, password })
      .then(response => {
        const data = response.data;
        commit("saveLogin", data);
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  update({ commit }, { password }) {
    return axios
      .post("/auth/update", { password })
      .then(response => {
        const data = response.data;
        commit("saveLogin", data);
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};

const mutations = {
  skipPasswordChange: state => {
    state.skipPasswordChange = true;
  },
  saveLogin: (state, data) => {
    if (data.token) {
      window.sessionStorage.setItem("k-chat-bot-session", data.token);
      window.sessionStorage.setItem(
        "k-chat-bot-session.changed",
        data.isChangedRequired
      );
      state.token = data.token;
      state.isChangedRequired = data.isChangedRequired;
    } else {
      window.sessionStorage.removeItem("k-chat-bot-session");
      window.sessionStorage.removeItem("k-chat-bot-session.changed");
      state.token = undefined;
      state.isChangedRequired = undefined;
    }
    state.skipPasswordChange = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
